@use "variables";

@media screen and (max-width: 1000px) {
    .about-view-fourth-part {
        padding: 50px 0 !important;
        margin-top: 0 !important;

        .about-view-fourth-part-content {
            flex-direction: column-reverse;

            .about-view-fourth-part-left-content {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
            }

            .about-view-fourth-part-image {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
                height: 300px !important;
            }
        }
    }
}

.about-view-fourth-part {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    margin-top: -140px;

    .about-view-fourth-part-content {
        display: flex;
        gap: 60px;
        height: 100%;
        justify-content: center;
        align-items: center;

        .about-view-fourth-part-left-content {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 600px;
            gap: 30px;

            .about-view-fourth-part-left-content-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
                margin-right: 50px;
                row-gap: 30px;

                .about-view-fourth-part-left-content-grid-item {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    text-align: center;

                    &:not(:last-child) {
                        border-right: 1px solid #BCBCBC;
                    }

                    h5 {
                        padding: 0 10px;
                    }
                }
            }
        }

        .about-view-fourth-part-image {
            width: 40vw;
            max-width: 500px;
            height: 400px;
            max-height: 550px;
            background-image: url("../../../assets/images/about/about-part-3.jpg");
            background-size: cover;
            background-position: top;
            box-shadow: -40px 40px 0 0 variables.$primary-color;
            flex-shrink: 0;
        }

    }
}