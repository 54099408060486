@use "variables";

@media screen and (max-width: 1000px) {
    .about-view-third-part {
        padding: 100px 0 !important;
    }
}

.about-view-third-part {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 180px 0;

    h2 {
        z-index: 1;
        color: white;
        text-align: center;
        max-width: 1000px;
        font-size: 2.5rem;
        font-family: "bold", sans-serif;
        line-height: 3.2rem;
        text-shadow: 0 0 60px #000;
        text-transform: uppercase;
        padding: 0 50px;
    }

    .about-view-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, rgba(1, 165, 37, 0.3) 0%, rgba(1, 165, 37, 0.3) 100%), url("../../../assets/images/about/about-part-2.jpg") lightgray 50% / cover no-repeat;
        background-size: cover;
        filter: blur(3px);
        transform: scale(1.01);
    }
}