@use "variables";

@media screen and (max-width: 1000px) {
    .contact-view-first-part {
        height: 30vh !important;
        flex-direction: column;

        .contact-view-background {
            top: 0 !important;
            left: 0 !important;
        }

        .contact-view-left-part {
            display: none;
        }

        .contact-view-left-part-content {
            h3 {
                font-size: 3rem !important;
                line-height: 3rem !important;
                text-shadow: 0 0 30px #000;
            }
        }
    }
}

.contact-view-first-part {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100vw;
    overflow: hidden;
    flex-shrink: 0;

    .contact-view-background {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(0deg, rgba(1, 165, 37, 0.3) 0%, rgba(1, 165, 37, 0.3) 100%), url("../../../assets/images/contact/background.jpg") lightgray 50% / cover no-repeat;
        background-size: cover;
        filter: blur(3px);
        transform: scale(1.01);
    }

    .contact-view-left-part {
        position: absolute;
        bottom: -30%;
        left: -22%;
        width: 50%;
        height: 50%;
        background-color: variables.$background-color;
        transform: rotate(-5deg) skewX(-10deg);
        border: 10px solid variables.$primary-color;
    }

    .contact-view-left-part-content {
        position: absolute;
        bottom: 4%;
        left: 9%;
        display: flex;
        flex-direction: column;
        color: white;
        transform: translateY(-40%);
        gap: 40px;

        h3 {
            font-size: 3vw;
            font-family: "semibold", sans-serif;
            line-height: 3vw;
        }
    }
}