@use "variables";

@media screen and (max-width: 1000px) {
    .services-view-third-part {
        padding: 25px 0 !important;
        margin-bottom: 40px;

        .services-view-third-part-content {
            flex-direction: column-reverse;
            gap: 30px !important;

            .services-view-third-part-left-content {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
            }

            .services-view-third-part-image {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
                height: 400px !important;

                .services-view-third-part-icon {
                    display: none !important;
                }
            }
        }
    }
}

.services-view-third-part {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    gap: 150px;

    .services-view-third-part-content {
        display: flex;
        gap: 100px;
        height: 100%;
        justify-content: center;
        align-items: center;

        .services-view-third-part-left-content {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 600px;
            gap: 30px;

            .services-view-third-part-left-content-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
                gap: 10px;
            }
        }

        .services-view-third-part-image {
            position: relative;
            width: 40vw;
            max-width: 450px;
            height: 550px;
            max-height: 550px;
            background-image: url("../../../assets/images/services/services-part-2.jpg");
            background-size: cover;
            background-position: center;
            box-shadow: -40px 40px 0 0 variables.$primary-color;
            flex-shrink: 0;

            .services-view-third-part-icon {
                position: absolute;
                top: 30px;
                right: -50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background-color: variables.$primary-color;
                border-radius: 100%;
            }
        }

    }
}