@use "variables";

.custom-button {
    border: 5px solid variables.$primary-color;
    font-size: 1.2rem;
    font-family: "semibold", sans-serif;
    width: fit-content;
    padding: 15px 30px;
    transition: all 0.2s ease;
    cursor: pointer;
    text-transform: uppercase;
    flex-shrink: 0;

    &:not(.custom-button-primary) {
        background-color: transparent;
        color: variables.$primary-color;
    }

    &.custom-button-primary {
        background-color: variables.$primary-color;
        color: white;
    }

    &:hover {
        background-color: variables.$primary-color;
        color: white;
    }

    &:disabled {
        background-color: #dddddd;
        border: 5px solid #dddddd;
        color: white;
        cursor: not-allowed;
    }
}