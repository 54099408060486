@use "variables";

@media screen and (max-width: 1000px) {
    html, body, #root {
        font-size: 70% !important;
    }

    *::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

body {
    -webkit-text-size-adjust: none !important;
    user-select: none !important;
}

* {
    user-scalable: none !important;
}

html, body, #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    overflow: hidden;
}

div,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
}

h1, h2, h3, h4, h5, h6, p, a {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

h2 {
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-family: "medium", sans-serif;
}

h3 {
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: "semibold", sans-serif;
}

h4 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-family: "regular", sans-serif;
}

h5 {
    font-size: 1.3rem;
    line-height: 2rem;
    font-family: "thin", sans-serif;
}

p {
    color: variables.$text-color;
    font-size: 1.2rem;
    line-height: 2rem;
}

.fill-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.flex-fill {
    display: flex;
    height: 100%;
    width: 100%;
}

.flex-column {
    flex-direction: column;
}

.flex-center {
    display: flex;
    align-items: center;
}

.primary-color {
    color: variables.$primary-color;
}

.no-shrink {
    flex-shrink: 0 !important;
}