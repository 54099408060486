@use "variables";

@media screen and (max-width: 1000px) {
    .home-view-third-part {
        padding: 25px !important;
        margin-bottom: 30px;

        .home-view-third-part-header {
            flex-direction: column;
            gap: 50px !important;
        }
    }
}

.home-view-third-part {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: calc(100vw - 50px);
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: variables.$grey-color;
    gap: 80px;
    padding: 100px 50px;

    .home-view-third-part-header {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1000px;
        gap: 20px;

        h2 {
            width: 100%;
        }
    }

    .home-view-third-part-content-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
        gap: 50px;
        width: 1000px;
        max-width: calc(100vw - 100px);
        margin: 0 50px;

        .home-view-third-part-content-grid-item {
            display: flex;
            min-width: 300px;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            text-align: center;

            .home-view-third-part-content-grid-item-rectangle {
                width: 80px;
                height: 6px;
                background-color: variables.$primary-color;
                opacity: 0.3;
                margin-top: 10px;
            }
        }
    }
}