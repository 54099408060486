@use "variables";

@media screen and (max-width: 1000px) {
    .contact-view-second-part {
        padding: 30px 0 !important;

        .contact-view-second-part-content {
            flex-direction: column-reverse;
            gap: 50px !important;

            .contact-view-second-part-form-content {
                padding: 0 !important;
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
                gap: 20px !important;
            }

            .contact-view-second-part-left-content {
                width: 100% !important;
                max-width: calc(100% - 150px) !important;
                margin-left: 0 !important;
                margin-bottom: 50px;
            }
        }
    }
}

.contact-view-second-part {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    .contact-view-second-part-content {
        display: flex;
        gap: 100px;
        height: 100%;
        justify-content: center;
        align-items: center;

        .contact-view-second-part-left-content {
            display: flex;
            flex-direction: column;
            width: 30vw;
            max-width: 500px;
            gap: 30px;
            box-shadow: -40px 40px 0 0 variables.$primary-color;
            background-color: variables.$background-color;
            color: white;
            padding: 50px;
            margin-left: 80px;

            p {
                color: white;
            }

            h4 {
                font-size: 2rem;
                font-family: "semibold", sans-serif;
                line-height: 2rem;
            }

            h5 {
                font-size: 1.5rem;
                font-family: "semibold", sans-serif;
                line-height: 2rem;
            }

            h6 {
                font-size: 1.2rem;
                font-family: "regular", sans-serif;
                line-height: 2rem;
            }

        }

        .contact-view-second-part-form-content {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 600px;
            gap: 30px;
            padding: 50px;

            input, textarea {
                background-color: variables.$background-color;
                color: white;
                border: transparent;
                outline: none;
                border-bottom: 6px solid variables.$primary-color;
                padding: 20px;
                font-size: 1.2rem;
                font-family: "regular", sans-serif;

                &::placeholder {
                    color: darkgray;
                }

                &.field-error {
                    border-bottom: 6px solid red;
                }
            }

            .contact-view-second-part-form-content-checkbox-container {
                display: flex;
                gap: 20px;
                align-items: center;
            }

            input[type="checkbox"] {
                position: relative;
                border: transparent;
                width: 30px;
                height: 30px;
                margin: 0;
                cursor: pointer;
                color: white;
                flex-shrink: 0;

                &:checked::before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: variables.$primary-color;
                    border: 5px solid variables.$background-color;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &:not(:checked)::before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: variables.$background-color;
                    border: 5px solid variables.$primary-color;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            textarea {
                min-height: 80px;
            }

            .contact-view-second-part-form-content-button-container {
                display: flex;
                justify-content: end;
            }
        }
    }
}