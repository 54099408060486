@use "variables";

.check-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0;

    p {
        color: variables.$text-color;
    }
}