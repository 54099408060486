@media screen and (max-width: 1000px) {
    .legal-information-view {
        .legal-information-view-content {
            margin-top: 70px !important;
        }
    }
}

.legal-information-view {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .legal-information-view-content {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: calc(100% - 50px);
        padding: 30px 25px;
        margin-top: 70px;

        h2 {
            margin-bottom: 20px;
        }

        h4 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        p {
            color: black;
            margin-bottom: 15px;
        }
    }
}