@use "variables";

@media screen and (max-width: 1000px) {
    .about-view-second-part {
        padding: 30px 0 !important;
        margin-bottom: 30px;

        .about-view-second-part-content {
            flex-direction: column;
            gap: 50px !important;

            .about-view-second-part-left-content {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;

                .about-view-second-part-left-content-grid {
                    margin-right: 0 !important;

                    .about-view-second-part-left-content-grid-item {
                        border-right: none !important;
                    }
                }
            }

            .about-view-second-part-image {
                width: 100% !important;
                max-width: calc(100% - 50px) !important;
                height: 400px !important;
            }
        }
    }
}

.about-view-second-part {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100vw;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    .about-view-second-part-content {
        display: flex;
        gap: 50px;
        height: 100%;
        justify-content: center;
        align-items: center;

        .about-view-second-part-left-content {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 600px;
            gap: 30px;

            .about-view-second-part-left-content-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
                margin-right: 50px;
                row-gap: 30px;

                .about-view-second-part-left-content-grid-item {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    text-align: center;

                    &:not(:last-child) {
                        border-right: 1px solid #BCBCBC;
                    }

                    h5 {
                        padding: 0 10px;
                    }
                }
            }
        }

        .about-view-second-part-image {
            width: 40vw;
            max-width: 500px;
            height: 100%;
            max-height: 550px;
            background-image: url("../../../assets/images/about/about-part-1.jpg");
            background-size: cover;
            background-position: center;
            box-shadow: 40px 40px 0 0 variables.$primary-color;
            flex-shrink: 0;
        }

    }
}