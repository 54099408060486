@use "variables";

@media screen and (max-width: 1000px) {
    .about-view-fifth-part {
        padding: 25px !important;
        gap: 25px !important;
    }
}

.about-view-fifth-part {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 50px);
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    gap: 50px;
    padding: 30px 50px 100px 50px;

    .about-view-fifth-part-header {
        display: flex;
        gap: 200px;
    }

    .about-view-fifth-part-content-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) !important;
        gap: 40px;
        width: 1130px;
        max-width: calc(100vw - 50px);
        overflow: hidden;

        .about-view-fifth-part-content-grid-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            background-color: variables.$background-color;
            color: white;
            border-bottom: 10px solid variables.$primary-color;
            padding: 30px;

            > :first-child {
                padding-bottom: 20px;
            }

            > * {
                width: 100%;
            }
        }
    }
}