@use "variables";

@media screen and (max-width: 1000px) {
    .footer {
        .footer-top-part {
            height: fit-content !important;
            padding: 50px;

            .footer-background {
                display: none;
            }

            .footer-top-left-part {
                display: none;
            }

            .footer-top-left-part-content {
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
                transform: none !important;

                h4 {
                    font-size: 2rem !important;
                    line-height: 3rem !important;
                }

                h5 {
                    font-size: 1.5rem !important;
                    line-height: 2rem !important;
                }

                h6 {
                    font-size: 1.2rem !important;
                    line-height: 2rem !important;
                }
            }
        }

        .footer-bottom-part {
            gap: 30px !important;
            flex-direction: column;

            & > div:last-child {
                justify-content: center !important;
            }
        }
    }
}

.footer {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    background-color: variables.$background-color;
    color: white;
    flex-shrink: 0;

    .footer-top-part {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 400px;
        overflow: hidden;
        flex-shrink: 0;
        border-bottom: 10px solid variables.$primary-color;

        .footer-background {
            position: absolute;
            top: 0;
            left: 30%;
            width: 70vw;
            height: 100%;
            background: linear-gradient(0deg, rgba(1, 165, 37, 0.25) 0%, rgba(1, 165, 37, 0.25) 100%), url("../../assets/images/footer.jpg") lightgray 50% / cover no-repeat;
            background-size: cover;
            background-position: center;
        }

        .footer-top-left-part {
            position: absolute;
            top: -50%;
            left: -50%;
            width: 90%;
            height: 300%;
            background-color: variables.$background-color;
            transform: rotate(-20deg);
            border: 10px solid variables.$primary-color;
        }

        .footer-top-left-part-content {
            position: absolute;
            top: 50%;
            left: 8%;
            display: flex;
            flex-direction: column;
            color: white;
            transform: translateY(-50%);
            gap: 20px;

            h4 {
                font-size: 2vw;
                font-family: "semibold", sans-serif;
                line-height: 3vw;
            }

            h5 {
                font-size: 1.5vw;
                font-family: "semibold", sans-serif;
                line-height: 2vw;
            }

            h6 {
                font-size: 1.2vw;
                font-family: "regular", sans-serif;
                line-height: 2vw;
            }

            .rectangle-decoration {
                width: 100px;
                height: 7px;
                background-color: variables.$primary-color;
            }
        }
    }

    .footer-bottom-part {
        background-color: variables.$background-color;
        display: flex;
        gap: 50px;
        padding: 40px 60px;

        p {
            color: white;
        }

        a {
            font-size: 1.5rem;
            color: variables.$primary-color;
        }

        & > div:first-child {
            display: flex;
            flex-direction: column;
            gap: 10px;
            max-width: 60vw;
            flex-shrink: 0;
        }

        & > div:last-child {
            display: flex;
            gap: 20px;
            width: 100%;
            justify-content: right;
            align-items: center;
        }

        button {
            border: 0;
            outline: 0;
            background-color: transparent;
            color: white;
            cursor: pointer;
        }
    }
}