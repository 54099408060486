@use "variables";

@media screen and (max-width: 1000px) {
    .home-view-second-part {
        padding: 25px !important;

        .home-view-second-part-content {
            width: 100%;
            flex-direction: column;
            gap: 30px !important;

            .home-view-second-part-left-content {
                width: 100% !important;
                max-width: 100% !important;
            }

            .home-view-second-part-image {
                width: 100% !important;
                height: 400px !important;
                margin-bottom: 50px;
                max-width: calc(100vw - 50px) !important;
            }
        }
    }
}

.home-view-second-part {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: calc(100vw - 50px);
    flex-shrink: 0;
    align-items: center;
    padding: 100px 50px;

    .home-view-second-part-content {
        display: flex;
        gap: 50px;
        height: fit-content;
        align-items: center;

        .home-view-second-part-left-content {
            display: flex;
            flex-direction: column;
            width: 40vw;
            max-width: 600px;
            gap: 30px;

            .home-view-second-part-left-content-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
                gap: 10px;
            }
        }

        .home-view-second-part-image {
            width: 40vw;
            max-width: 600px;
            height: 100%;
            max-height: 550px;
            background-image: url("../../../assets/images/home/home_second_part.jpg");
            background-size: cover;
            background-position: center;
            box-shadow: 40px 40px 0 0 variables.$primary-color;
            flex-shrink: 0;
        }

    }
}